import React from "react";
import "../styles/herbpharm.scss";
import Seo from "../components/seo";
import Header from "../components/herbpharm/header/header";
const StripeCancelPage = () => {
    return (
        <>
            <div className='app' id='top'>
                <Seo title="Acuboston Herbal Pharmacy"/>
                <Header />
                <div style={ { width: '100%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' } }>
                    <h1>Canceled Payment</h1>
                </div>
            </div>
        </>
    )
}

export default StripeCancelPage;
